import React from 'react'
import NextErrorComponent, { ErrorProps } from 'next/error'
import { NextPage } from 'next'

interface AppErrorProps extends ErrorProps {
  err?: Error
  hasGetInitialPropsRun?: boolean
}

const AppError: NextPage<AppErrorProps> = ({ statusCode }) => {
  return <NextErrorComponent statusCode={statusCode} />
}

AppError.getInitialProps = async (ctx) => {
  const errorInitialProps: AppErrorProps =
    await NextErrorComponent.getInitialProps(ctx)
  errorInitialProps.hasGetInitialPropsRun = true
  if (ctx.err) {
    return errorInitialProps
  }

  return errorInitialProps
}

export default AppError
